import React from "react";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";


import * as styles from "../styles/pages/contacts.module.css";
import * as sharedStyles from "../styles/shared.module.css";

export const query = graphql`
  query ContactsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      surgeonName
      title
      description
      keywords
    }
  }
`;

const IndexPage = props => {
  const { data } = props;

  const site = data?.site;
  const about = data?.about
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div style={{ display: 'flex' }}>
        <h2 className={sharedStyles.sectionTitle}>Контакты</h2>
      </div>
      <div className={styles.main}>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            Адреса клиник
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../media/map.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5>Хартман Клиник</h5>
                <p>Москва, Мясницкая ул., 19</p>
              </div>
              <div className={styles.sectionItemDesc}>
                <p>Google Maps: <a rel="noopener noreferrer" target="_blank" href="https://g.co/kgs/yDGTFdN">g.co/kgs/yDGTFdN</a></p>
              </div>
            </div>
          </div>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../media/map.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5>Клиника кожи</h5>
                <p>Москва, Банный пер., 2с2</p>
              </div>
              <div className={styles.sectionItemDesc}>
                <p>Сайт клиники: <a rel="noopener noreferrer" target="_blank" href="https://dermclinic.ru">dermclinic.ru</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            Мои контакты для записи
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../media/email.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a href="mailto:Viktoriaa@yandex.ru">Viktoriaa@yandex.ru</a></h5>
              </div>
            </div>
          </div>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} src="../media/social.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a href="tel:+79263951759">+7 (926) 395-17-59</a></h5>
                <p>По любым вопросам и для записи пишите в WhatsApp</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionItem}>
          <h4 className={styles.sectionTitle}>
            Социальные сети
          </h4>
          <div className={styles.sectionItemPart}>
            <StaticImage objectFit="contain" className={styles.sectionIcon} width={70} src="../media/inst.svg" />
            <div className={styles.sectionPartRight}>
              <div className={styles.sectionItemDesc}>
                <h5><a rel="noopener noreferrer" className="asterisk" target="_blank" href="https://www.instagram.com/dr.andreevavv/">dr.andreevavv</a></h5>
                <h5><a rel="noopener noreferrer" className="asterisk" target="_blank" href="https://www.instagram.com/dr.viktoriaaplastic">dr.viktoriaaplastic</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={sharedStyles.transitionMargin}>
        <StaticImage src="../media/BackgroundShift.svg" objectFit="contain" alt="Background transition" className={sharedStyles.backgroundTransition} />
      </div> */}
    </Layout>
  );
};

export default IndexPage;
